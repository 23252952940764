.container {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	margin-right: auto;
	margin-left: auto;
}

.ul {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	border-radius: 15px 15px 0 0;
	transform: scale(0.75);
	transform-origin: bottom;
	transition: transform 0.15s ease, opacity 0.15s ease, background 0.15s ease;
	background-color: rgb(0 0 0 / 30%);
	backdrop-filter: blur(45px);

	&:hover,
	&:focus-within {
		opacity: 1;

		@media (min-width: 768px) {
			transform: scale(1);
		}
	}
	li {
		&:first-child {
			.button {
				@apply pl-6;
			}
		}

		&:last-child {
			.button {
				@apply pr-6;
			}
		}
	}
}

.button {
	@apply text-button-medium text-white px-4 py-2 flex justify-center items-center;
	text-decoration: none;
	border: none;
	border-radius: none;
	transition: background 0.15s ease;
	height: 100%;

	svg {
		@apply mr-1;
		width: 1rem;
	}

	&[data-exit] {
		svg {
			stroke: currentColor;
		}

		&:focus-visible,
		&:hover {
			background-color: rgb(167 24 24);
		}
	}

	&[data-clean] {
		svg {
			fill: currentColor;
		}

		&:focus-visible,
		&:hover {
			background-color: rgb(141 115 44);
		}
	}

	&[data-edit] {
		svg {
			fill: currentColor;
		}

		&:focus-visible,
		&:hover {
			background-color: rgb(67 67 175);
		}
	}
}
